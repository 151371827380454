// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  height: 100%;\n  min-height: 100vh;\n  background: linear-gradient(#000000, #212121);\n  background-repeat: no-repeat;\n  background-size: cover;\n  color: white;\n  font-family: \"Roboto\", sans-serif;\n  -webkit-text-size-adjust: none;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,6CAA6C;EAC7C,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,iCAAiC;EACjC,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\");\n\nbody {\n  height: 100%;\n  min-height: 100vh;\n  background: linear-gradient(#000000, #212121);\n  background-repeat: no-repeat;\n  background-size: cover;\n  color: white;\n  font-family: \"Roboto\", sans-serif;\n  -webkit-text-size-adjust: none;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
